* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans;
}

.bg {
     background-image: url("../../assets/bg.png"); 
    background-size: cover; 
    font-family: 'Nunito', sans;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    margin: 0;
}

.bg .feedtainer h2 {
    margin-top: 25px;
    font-size: 4em;
    color: #04115d;
}

.bg .feedtainer h4 {
    color: #04115d;
    font-size: 20px;

}

.bg .feedtainer p {
    font-size: 15px;
    color: darkblue;
}

.feedtainer img {
    width: 100px;
    height: 100px;
    float: right;
}

.feedtainer {
    /* background-color: #b6b5b54d;  */
    background: linear-gradient(#ffffffff, #ffffffff);
    border-radius: 10px;
    padding: 30px;
    width: 80%;
    height: 80%;
    text-align: center;
}

.feed-container {
    display: grid;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
}

.feedbackbox label {
    width: 100%;
    top: 50%;
    left: 10px;
    justify-content: center;
    transform: translate(-50%);
    font-size: 1.5rem;
    pointer-events: none;
    transition: .3s;
    font-weight: bolder;
}

.feedbackbox input {
    width: 75%;
    height: 50px;
    font-size: 20px;
    padding: 0 35px 0 5px;
    color: #04115d;
    outline-color: #04115d;
    border: 4px solid #3d425e;
    border-radius: 5px;
    align-items: center;
    transition: .5s ease-out;
    background: linear-gradient(#ffffff59, #ffffff36);
    cursor: pointer;
    border: 2px solid #656464;
}

/* Chrome, Safari, Edge, Opera */
.feedbackbox input::-webkit-outer-spin-button,
.feedbackbox input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.feedbackbox input[type=number] {
    -moz-appearance: textfield;
}

.feedbackbox input:focus {
    outline: none;
    border-bottom: 2px solid #04115d;
}

.feedbackbox i {
    color: #04115d;
    position: absolute;
    right: 8px;
    font-size: 1rem;
    top: 20px;
}

.feedbackbox span {
    color: #9e0d0d;
    font-size: 12px;
}

.danger {
    color: #9e0d0d;
    font-size: 12px;
}



.otpbtnc{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    
}

.otpbtnc button {
    display: flex;
    margin: 20px 50px;
    cursor: pointer;
    border: 0;
    border-radius: 15px;
    font-weight: 600;
    width: 180px;
    height: 80px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    color: #020a35;
    background-color: #0518836d;
    border: 1px solid #04115d;
    font-size: 18px;
}
.otpbtnc button span{
    padding-left: 40px;
    margin-top: 20px;
    color: #fff;
    font-size: 22px;
}

.otpbtnc button span:hover{
    margin-top: 20px;
    color: #fff;
}

.otpbtnc button:hover{
    color: #fff;
    /* width: 200px; */
    box-shadow: 0 0 20px #a6a5a3;
    background-color: #04115d;
    border-radius: 15px;
    width: 190px;
}

.otpbtnc {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fdimg-container img{
    margin-left: 10px;
    width: 70px;
    height: 60px;
}

/*media queries  Orientation in landscape */
@media only screen 
and (min-device-width:768px) 
and (max-device-width:1024px)
 and (orientation:landscape){
   
    .feedtainer {
        height: 80%;
        width: 80%;
        flex-direction: row;


    }
    .feed-container {

    }
   
}

/*media queries  Orientation in portrait */
@media only screen 
and (min-device-width:768px) 
and (max-device-width:1024px)
 and (orientation:portrait){
    .bg{
        margin: 0;
        padding: 0;
    }
    .feedtainer {
        height: 95vh;
        width: 80%;
        flex-direction: column;

    }
    .feed-container {
        flex-wrap: wrap;
    }
}

/*media queries for mobile  Orientation in landscape */
@media only screen 
and (min-device-width:319px) 
and (max-device-width:480px)
 and (orientation:landscape){
    .feedtainer {
    height: 100vh;
    flex-direction: row;
    }
  
}

/*media queries for mobile  Orientation in portrait */
@media only screen 
and (min-device-width:320px) 
and (max-device-width:480px)
 and (orientation:portrait){
    .feedtainer {
        height: 100vh;
        flex-direction: column;

    }
    .feed-container {
        flex-wrap: wrap;
    }

}





/* @media screen and (min-width: 40em) {
    .bg{
        height: 100vh;
        display: flex;
    }
    .feed-container{
        height: 100vh;
        flex-wrap: wrap;
    }
    .feedtainer {
        height: 100vh;
        width: 90%;
    }
    .bg .feedtainer h2 {
        margin:-10px;
        font-size: 2.5em;
        color: #04115d;
    }
}
 
@media screen and (min-width: 60em) {
    .feedtainer {
        height: 100vh;
        width: 90%;
        flex-direction: row;
    }
     .feedtainer h2 {
        margin:-10px;
        font-size: 3em;
        color: #04115d;
    }
}
 
 
@media screen and (min-width: 52em) {
    .feedtainer {
        width: 90%;
    }
    h2 {
        margin:-10px;
        font-size: 1em;
        color: #04115d;
    }
} */
