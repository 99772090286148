*{
    margin: 0;
    padding: 0;
    font-family: 'Nunito' sans;
}



.success {
    width: 100%;
    height: 500px;
    position: relative;
    color: #04115d;
    text-align: center;
  }
  
  .success img {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index:99
  }
  
  .success .soverlay {
    width: 100%;
    height: 100vh;
    background: #04115deb;
    position: absolute;
    top: 0;
    left: 0;
    z-index:999
  }
  
  .success h2 {
    line-height: 600px;
    text-align: center;
    margin: 0 auto;
    font-size: 2.3em;
    color: #fff;
  }

  .logo-box{
    display: flex;
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 200px;
    height:180px;

  }

  .logo-box img{
        position: absolute;
        width: 150px;
        height: 150px;
    
  }