*{
    margin: 0;
    padding: 0;
    font-family: 'Nunito' sans;
}
Link{
    color: #fff;
  }


.showcase {
    width: 100%;
    height: 500px;
    position: relative;
    color: #04115d;
    text-align: center;
  }
  
  .showcase img {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index:99
  }
  
  .showcase .overlay {
    width: 100%;
    height: 100vh;
    background: #04115dd2;
    position: absolute;
    top: 0;
    left: 0;
    z-index:999
  }
  
  
 
  
  .cd {
    width: 90%;
    height: 80%;
    display: grid;
    position: absolute;
    background: linear-gradient(#ffffff, #ffffff);
    border-radius: 8px;
    align-items: center;
    text-align: center;
    /* padding-left: 2rem;
    padding-right: 2rem; */
    box-sizing: border-box;
    /* margin-left: 100px;
    margin-right: 100px;  */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

 .cd h2 {
    padding-top: 40px;
    font-size: 2.3em;
  }

.userbox{
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

 .userbox label{
   font-size: 20px;
   color: #04115d;
   margin: 10px auto;
}

.userbox input[type="number"]{
    font-size: 20px;
    color: #04115d;
    width: 350px;
    height: 40px;
    border: 4px solid #3d425e;
    border-radius: 5px;
    align-items: center;
    transition: .5s ease-out;
    background: linear-gradient(#ffffff59, #ffffff36);
    cursor: pointer;
    border: 2px solid #656464;


 }

 .userbox input[type="number"]::-webkit-outer-spin-button, 
	.userbox input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;

	}

    .radiobox{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    }

     .radiogroup{
        display: flex;
        flex-wrap:nowrap;
        align-items: center;
        justify-content: center;  
        /* margin-top: -10px; */        
    }
  
    .img-container {
        width: 80px;
        height: 80px;
        position: relative;
        margin: 20px;
    }
    
    .img-container img {
        width: 60px;
        height: 60px;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
    }
    .radiobox .radiogroup input[type="radio"]{
        width: 30px;
        height: 20px;

    }

    .radiogroup span{
        width: 25px;
        height: 25px;
        font-size: 18px;
        margin:  auto 20px;
        color: #04115d;
        font-weight: bold;
    }

    .radiogroup span:hover{
        width: 25px;
        height: 25px;
        font-size: 20px;
        margin:  auto 20px;
        color: #fff;
    }

    .btnrad{
        margin-top: 20px;
        height: 80px;
        background: linear-gradient(#72a6f536, #8dd9f536);
        /* margin-left: 30px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px;
        border: 1px solid #04115d;
        transition: .3s ease-in-out;
        border-radius: 10px;
        

    }

    .btnrad:hover{
        /* margin-top: 30px; */
        height: 80px;
        background: #04115da5;
    }

    .cd .logo-container{
        display: flex;
        position: absolute;
        right: 150px;
        bottom: 0px;
        width: 100px;
        height: 80px;
        margin: 180px auto;
        
    }
    .cd .btm-img img {
        float: right;
        right: 150px;
        width: 80px;
        height: 80px;
        display: flex;
        position: absolute;
        bottom: 0;
        margin-left: 90%;
        margin-top: 35%;

    }

    @media screen and (min-width:768px) {
        .userbox{
            display: grid;
        }

        .btm-img{

        }
    }


@media screen and (min-width:768px) {

    *{
        margin: 0;
        padding: 0;
        font-family: 'Nunito' sans;
    }
    .showcase {
        color: #04115d;
        text-align: center;
        padding: 0;
      }
    .cd {
        padding: 0;
        margin: 0;      
    }

    .img-container {
        width: 80px;
        height: 80px;
        position: relative;
        margin: 20px;
    }
    
    .img-container img {
        width: 75px;
        height: 75px;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
    }
    .userbox{
        display: grid;
    }
.cd .btm-img{
    right: 0;
    margin-left: 20%;
    margin-top: 70%;
    position: fixed;
    margin-left: 20%;
    margin-top: 70%;
   
}
    .cd .btm-img img {
       /* display: none; */
       float: right;
       right: 150px;
       width: 80px;
       height: 80px;
       margin-left: 20%;
       margin-top: 70%;
       bottom: 0;
       position: fixed;

      

    }
}


@media screen and (min-width:992px) {

    *{
        margin: 0;
        padding: 0;
        font-family: 'Nunito' sans;
    }
    .showcase {
        color: #04115d;
        text-align: center;
        padding: 0;
      }
    .cd {
        display: absolute;
        padding: 0;
        margin: 0;      
    }
  


    .img-container {
        width: 80px;
        height: 80px;
        position: relative;
        margin: 20px;
    }
    
    .img-container img {
        width: 75px;
        height: 75px;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
    }
    .cd .btm-img{
        margin-top: 100px;
        place-items: end;
    }

    .cd .btm-img img {
        float: right;
        right: 150px;
        width: 80px;
        height: 80px;
        display: flex;
        bottom: 0;
     

    }
    form{
        margin-top: -20px;
    }
}

@media screen and (max-width:1200px) {

    *{
        margin: 0;
        padding: 0;
        font-family: 'Nunito' sans;
    }
    .showcase {
        color: #04115d;
        text-align: center;
        padding: 0;
      }
    .cd {
        padding: 20;
        margin: 20;      
    }
    h2{
        margin-top: 30px;
        padding-top: 50px;
    }

    .userbox{
        margin-top: 40px;
    }


    .img-container {
        width: 80px;
        height: 80px;
        position: relative;
        margin: 20px;
    }
    
    .img-container img {
        width: 75px;
        height: 75px;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
    }
    .cd .btm-img{
        width: 80px;
        height: 80px;
        position: fixed;
        bottom: 0;
        margin-top: 35%;
    }

    .cd .btm-img img {
        float: right;
        right: 150px;
        width: 80px;
        height: 80px;
        position: fixed;
        bottom: 0;
        margin-left:90%;
        margin-top: 45%;

    }
   
}
  

  



