*{
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans;
}
.bglogin{
    /* background: #bed9e6;  */
    background: linear-gradient(rgba(224, 224, 224, 0.7), rgba(201, 214, 242, 0.7));

    background-size: cover;
    font-family: 'Nunito', sans;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;
}

.bglogin .container h4{
    font-size: 20px;
    color:#04115d;
}
.bglogin .container h2{
    font-size: 17px;
    color:#9e0d0d;
}
.bglogin .container p{
    font-size: 15px;
     color: #04115d;
}
.container img{
    float: right;
    width: 10px;
    height: 90px;
    border-radius: 30px;
}

.container{
    background-color: #ffffff4d;
    border-radius: 10px;
    padding: 30px;
    max-width: 1000px;
    text-align: center ;
}

.code-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;

}



.code-container p {
    color: #04115d;
}


 .resetpwdbox{
    position: relative;
     margin: 10px 0; 
    width: 250px;

}
.resetpwdbox label{
    position: absolute;
    top: 50%;
    left: 10px;
    justify-content: center;
    transform: translate(-50%);
    color: #696969;
     font-size: 0.7rem;
     pointer-events: none;
     transition: .3s;
}
select{
    width: 200px;
    height: 38px;
    border: 0.5px solid 04115d ;
    border-radius: 5px;
}

.resetpwdbox input:focus ~ label,
 .resetpwdbox input:focus ~ :valid{
     top: -1px;
}

 .resetpwdbox input{
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    padding:0 35px 0 5px;
    color: #000;
    border-bottom: 1px solid #000;
    outline-color: #0d90cc;
}
/* Chrome, Safari, Edge, Opera */
.resetpwdbox input::-webkit-outer-spin-button,
.resetpwdbox input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.resetpwdbox input[type=number] {
  -moz-appearance: textfield;
}


.resetpwdbox input:focus{
    outline: none;
    border-bottom: 2px solid #0d90cc;
}

 .resetpwdbox i{
    color: #0d90cc;
    position: absolute;
    right: 8px;
    font-size: 1rem;
    top: 20px;
}

.resetpwdbox span{
    color: #9e0d0d;
    font-size: 12px;
}

.toast-message{
    height:100px;
    background: rgb(150, 150, 232);
    color: #fff;
}

.danger {
    color: #9e0d0d;
    font-size: 12px;
}


@media screen and (max-width:600px) {
    .code-container{
        flex-wrap: wrap ;
    }
    .code{
        font-size: 24px;
        height: 50px;
        max-width: 50px;
        font-size: bold;
    }
}

.otpbtnclass .btnotp {
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-weight: 600;
    margin: 20px 10px;
    width: 200px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    color: #053c55;
    background-color: #ffffff;
    border: 1px solid #053c55;
    font-size: 18px;    
  }
  
      
   .btnotp:hover {
    color: #fff;
    width: 200px;
    box-shadow: 0 0 20px #a6a5a3;
    background-color: #04115d;
    border-radius: 4px;

  }

  .otpbtnclass{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen 
and (min-device-width:768px) 
and (max-device-width:1024px)
 and (orientation:portrait)
  {
    .bglogin{
    
        font-family: 'Nunito', sans;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    } 
}

@media only screen 
and (min-device-width:768px) 
and (max-device-width:1024px)
 and (orientation:landscape)
  {
    .bglogin{
    
        font-family: 'Nunito', sans;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    } 

    .container{
        background-color: #ffffff4d;
        border-radius: 10px;
        text-align: center ;
    }
    
    .code-container{
        display: flex;
        align-items: center;
        justify-content: center;
    
    }
}