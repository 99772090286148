.c i{
    color: #04115d;
    /* background: #04115d; */
    border-radius: 10px;

}
.c span{
    padding:5px;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
    color: #04115d;
}


.c button{
    font-family: 'Nunito', sans;
    width: 150px;
    height: 30px;
    margin-left: 30px;
    background: #fff;
    color: #04115d;
    border: 1px solid #04115d;
    border-radius: 5px;
    font-size: 18px;
}

.c  button:hover{
    font-family: 'Nunito', sans;
    width: 160px;
    height: 35px;
    margin-left: 20px;
    background: #04115d;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    transition: .5s ease-in-out;
}

.c .period{
    display: flex;
    margin: 10px auto;
}

.c .period form{
    display: flex;
    justify-content: center;
    align-items: center;
}

.c .period .str{
    display: flex;
    flex-wrap: nowrap;

}

  .c .period .end{
    display: flex;
    margin: 0;
    margin-left : 30px;
    font-family: 'Nunito', sans;


}

.c .period span{
    font-size: 15px;
    color: #04115d;
    margin-left: 30px auto;

}

.c .period input[type="date"]{
    font-family: 'Nunito', sans;
    width: 180px;
    height: 35px;
    margin-left : 5px auto;
    background: #fff;
    color: #04115d;
    border: 1px solid #04115d;
    border-radius: 5px;
    font-size: 18px;
}

h4{
    justify-content: center;
    color: #e41c05;
}


.hed button{
    cursor: pointer;
    border: 0;
    border-radius: 8px;
    font-weight: 600;
    width: 200px;
    height: 40px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    color: #053c55;
    background-color: #ffffff;
    border: 1px solid #053c55;
    font-size: 18px;   

  }
  .hed button:hover{
    cursor: pointer;
    border-radius: 8px;
    font-weight: 600;
    width: 200px;
    height: 40px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    color: #053c55;
    background-color: #ffffff;
    border: 1px solid #053c55;
    font-size: 18px;   

  }


