* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans;
}

.bgsignup {
    /* background: linear-gradient(rgba(224, 224, 224, 0.7), rgba(201, 214, 242, 0.7)); */
    /* background-size: cover; */
    font-family: 'Nunito', sans;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    margin: 0;
}

.bgsignup .container h2 {
    font-size: 20px;
    color: #9e0d0d;
}

.bgsignup .container h4 {
    color: #04115d;
    font-size: 20px;

}

.bgsignup .container p {
    font-size: 15px;
    color: darkblue;
}

.container img {
    width: 100px;
    height: 100px;
}

.container {
    /* background-color: #b6b5b54d;  */
    background: linear-gradient(rgba(224, 224, 224, 0.7), rgba(201, 214, 242, 0.7));
    border-radius: 10px;
    padding: 30px;
    width: 800px;
    text-align: center;
}

.code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;

}

small {
    color: #0d90cc;
}

select {
    width: 200px;
    height: 38px;
    border: 0.5px solid 04115d;
    border-radius: 5px;
}

.resetpwdbox label {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 10px;
    justify-content: center;
    transform: translate(-50%);
    font-size: 0.7rem;
    pointer-events: none;
    transition: .3s;
}

.resetpwdbox input:focus~label,
.resetpwdbox input:focus~ :valid {
    top: -1px;
}

.resetpwdbox input {
    width: 81%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0 35px 0 5px;
    color: #000;
    border-bottom: 2px solid #04115d;
    outline-color: #04115d;
}

/* Chrome, Safari, Edge, Opera */
.resetpwdbox input::-webkit-outer-spin-button,
.resetpwdbox input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.resetpwdbox input[type=number] {
    -moz-appearance: textfield;
}


.resetpwdbox input:focus {
    outline: none;
    border-bottom: 2px solid #04115d;
}

.resetpwdbox i {
    color: #04115d;
    position: absolute;
    right: 8px;
    font-size: 1rem;
    top: 20px;
}

.resetpwdbox span {
    color: #9e0d0d;
    font-size: 12px;
}

.danger {
    color: #9e0d0d;
    font-size: 12px;
}



@media screen and (max-width:600px) {
    .code-container {
        flex-wrap: wrap;
    }
}



.otpbtnclas .btnotp {
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-weight: 600;
    margin: 20px 10px;
    width: 200px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    color: #04115d;
    background-color: #ffffff;
    border: 1px solid #04115d;
    font-size: 18px;
}


.otpbtnclas .btnotp:hover {
    color: #fff;
    /* width: 200px; */
    box-shadow: 0 0 20px #a6a5a3;
    background-color: #04115d;
    border-radius: 4px;

}

.otpbtnclas {
    display: flex;
    justify-content: center;
    align-items: center;
}