.hed i{
    color: #04115d;
    /* background: #04115d; */
    border-radius: 10px;

}
.hed span{
    padding:5px;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
    color: #04115d;
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    
  }

  #customers tr th{
    text-align: center;
    border: 1px solid #ddd;

  }
  
  #customers tr td{
    border: 0.11px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8ba1c9;
    color: #000;
  }

  .hed button{
    margin: 20px;
    margin-left: 40px ;
    cursor: pointer;
    border: 0;
    border-radius: 8px;
    font-weight: 600;
    margin: 20px 10px;
    width: 200px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    color: #053c55;
    background-color: #ffffff;
    border: 1px solid #053c55;
    font-size: 18px;   

  }

  .hed button:hover{
    width: 210px;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    color: #fff;
    background: #04115d;
  
  }

   tbody tr td button{
    background: #ff8d01;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    font-size: 20px; 
    width: 100px;
 
  }
  tbody tr td button:hover{
    background: #ff1601;
    color: #fff;
    width: 100px;
    transition: .5s all ease-out;
    cursor: pointer;
 
  }