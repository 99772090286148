.cards{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: 'Nunito', sans;
    margin-bottom: 20px auto;
}

.cards .card{
    font-family: 'Nunito', sans;
    margin: 2px 0px 20px 20px;
    width: 600px;
    height: 150px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(rgba(224, 224, 224, 0.7), rgba(201, 214, 242, 0.7));
    border-radius: 8px;
}

.cards .c1{
    background: #45C4B0;
    color: #04115d;
}

.cards .c2{
    background: #0ce973;
    color: #04115d;

}

.cards .c3{
   background:#1e7dc5;
   color: #04115d;

}

.cards .c4{
    background:#c0c51e;
    color: #04115d;
 
 }

.cards .card:hover{    
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #8f9ad3;
    color: #fff;
    transition: .3s all ease-in-out;
}

.card i{
    margin: 5px auto;
    color: #520120;
    font-size: 30px;
}

.card h3{
    margin: 5px auto;
    color: #520120;
}

.card span{
    margin: 10px auto;
    color: #5b3a0b;
    font-size: 30px;
    font-weight: 900;
}

.card span:hover{
    color: #fff;

}